import React from "react";
import i18n from "../i18n";
import LineItem from "./LineItem.jsx";

export default class ShoppingCart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shopping_cart: this.props.shopping_cart,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.shopping_cart !== prevProps.shopping_cart) {
      this.setState({
        shopping_cart: this.props.shopping_cart,
      });
    }
  }
  render() {
    return (
      <div id="shopping_cart--body">
        <div className="grid-x align-middle text-center hide-for-small-only global-weight-bold">
          <div className="cell medium-6 small-2 text-center ">Продукт</div>
          <div className="cell medium-2 text-center global-weight-bold">
            Брой
          </div>
          <div className="cell medium-2 text-center global-weight-bold">
            Цена
          </div>
          <div className="cell medium-2 text-center global-weight-bold">
            Общо
          </div>
        </div>
        <div id="shopping_cart--line-items">
          {this.state.shopping_cart.line_items.map((line_item) => (
            <LineItem
              key={line_item.variant_id}
              item={line_item}
              quantity_editable={this.props.quantity_editable}
            />
          ))}
        </div>

        {/*
        <div className="grid-x global-weight-bold shopping-cart--secondary-totals">
          <div className="cell medium-4 medium-offset-6 small-6 text-right">
            Междинна цена
          </div>
          <div className="cell medium-2 small-6 text-center">
            {this.state.shopping_cart.items_total}
          </div>
        </div>
      */}

        <div className="grid-x global-weight-bold shopping-cart--secondary-totals align-middle">
          <div className="cell medium-offset-4 medium-6 small-6 delivery_name">
            {this.state.shopping_cart.delivery_name}
          </div>
          <div className="cell medium-2 small-6 delivery_price">
            {this.state.shopping_cart.delivery_price}
          </div>
        </div>

        <div className="grid-x global-weight-bold shopping-cart--totals">
          <div className="cell medium-4 medium-offset-6 small-6 final_price_text">
            Крайна цена
          </div>
          <div className="cell medium-2 small-6 final_price_number">
            {this.state.shopping_cart.total}
          </div>
        </div>
      </div>
    );
  }
}
