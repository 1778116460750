import React, { useState, useEffect } from "react";
import i18n from "../i18n";
import ShoppingCartBody from "./ShoppingCartBody.jsx";
import PubSub from "pubsub-js";

const ShoppingCart = (props) => {
  // Initialize state using useState hooks
  const [shoppingCart, setShoppingCart] = useState(props.shopping_cart);
  const [visibilityClass, setVisibilityClass] = useState("");
  const [handleClass, setHandleClass] = useState("fa-shopping-cart");
  const [handleCountClass, setHandleCountClass] = useState("");

  // listens the shopping_cart topic and updates the state with the
  // shopping_cart object that is received
  useEffect(() => {
    const token = PubSub.subscribe("shopping_cart", (e, updatedCart) => {
      // this is triggered whenever we receive a shopping cart update
      // via the PubSub topic
      setShoppingCart(updatedCart);
      if (updatedCart.items_count > 0 && visibilityClass !== "toggled")
        animateCSS("#shopping-cart--toggler", "heartBeat");
    });

    // Cleanup subscription on unmount
    return () => {
      PubSub.unsubscribe(token);
    };
  }, [visibilityClass]);

  // gets triggered whenever the user clicks the toggler
  // three things happen:
  // - the handle icon changes to a times icon
  // - the shopping cart content gets into the view
  // - the item counter gets hidden
  const triggerShoppingCartToggle = () => {
    if (visibilityClass !== "toggled") {
      setVisibilityClass("toggled");
      setHandleClass("fa-times");
      setHandleCountClass("hidden");
    } else {
      setVisibilityClass("");
      setHandleClass("fa-shopping-cart");
      setHandleCountClass("");
    }
  };

  // this is needed when there are already items in the cart and the component is freshly loaded
  const initialAnimationClass = shoppingCart.items_count > 0 ? "active" : "";

  return (
    <div
      id="shopping-cart"
      className={
        "radius-large " +
        visibilityClass +
        " " +
        props.shopping_cart_prop_classes
      }
    >
      <div
        id="shopping-cart--toggler"
        className={initialAnimationClass + " " + props.toggler_classes}
        onClick={triggerShoppingCartToggle}
      >
        <span
          id="shopping_cart--toggler--item-count"
          className={"global-weight-bold " + handleCountClass}
        >
          {shoppingCart.items_count}
        </span>
        <i className={"fa " + handleClass}></i>
      </div>

      <ShoppingCartBody shopping_cart={shoppingCart} quantity_editable={true} />

      {props.actions_buttons ? (
        <div className="grid-x grid-margin-x" id="shopping-cart--bottom-button">
          <div className="cell small-6">
            <a
              href="/shop/cart/empty"
              className="button secondary no-margin wide hollow"
            >
              Изчисти
            </a>
          </div>
          <div className="cell small-6 text-right">
            <a href="/shop/payments/new" className="button no-margin wide">
              Плащане
            </a>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ShoppingCart;

const animateCSS = (element, animation, prefix = "animate__") =>
  // We create a Promise and return it
  new Promise((resolve, reject) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);

    node.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      resolve("Animation ended");
    }

    node.addEventListener("animationend", handleAnimationEnd, { once: true });
  });
