import React, { useState, useEffect } from "react";
import PubSub from "pubsub-js";
import i18n from "../i18n";

const AddToCart = (props) => {
  const [buttonState, setButtonState] = useState("");
  const [variantId, setVariantId] = useState("");
  const [error, setError] = useState("");

  // Set default variant_id if there's only one variant
  useEffect(() => {
    if (props.variants.length === 1) {
      const defaultVariant = props.variants.find((variant) => variant.default);
      if (defaultVariant) {
        setVariantId(defaultVariant.variant_id);
      }
    }
  }, [props.variants]);

  const handleVariantChange = (e) => {
    setVariantId(e.target.value);
    setError(""); // Clear error when a variant is selected
  };

  const addProduct = (e) => {
    e.preventDefault();

    if (!variantId) {
      setError("Please select a variant.");
      return;
    }

    const csrf = document.head.querySelector('meta[name="csrf-token"]').content;

    fetch("/shop/cart", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;",
        "X-CSRF-Token": csrf,
      },
      body: JSON.stringify({
        shopping_cart: {
          quantity: 1,
          variant_id: variantId,
        },
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setButtonState("");
        setError(""); // Clear error after successful request
        PubSub.publish("shopping_cart", data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  return (
    <div>
      {props.variants.length > 1 ? (
        <div className="grid-x align-middle">
          <div className="cell small-6 medium-12">
            <select
              id="variant-select"
              className={`no-margin ${error ? 'error-border' : ''}`}
              value={variantId}
              onChange={handleVariantChange}
            >
              <option value="">Размер</option>
              {props.variants.map((variant) => (
                <option key={variant.variant_id} value={variant.variant_id}>
                  {variant.variation}
                </option>
              ))}
            </select>
            <div className="hide-for-small-only"><br /></div>
          </div>
          <div className="cell small-6 medium-12">
            <a
              href="#"
              onClick={addProduct}
              className={`shopping-cart--add-to-cart-button button large wide ${buttonState}`}
            >
              Купи
            </a>
          </div>
        </div>
      ) : (
        <a
          href="#"
          onClick={addProduct}
          className={`shopping-cart--add-to-cart-button button large wide ${buttonState}`}
        >
          Купи
        </a>
      )}
    </div>

  );
};

export default AddToCart;
